@media (min-width: 544px) {
  .container-sm {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.container-sm {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  max-width: 640px;
}
.menu-section h2 {
  margin-bottom: 1rem;
}
.menu-item .menu-item__heading {
  font-size: 1.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.menu-section h2 {
  margin-bottom: 1rem;
}

.menu-section {
  margin-bottom: 0;
}

.menu-item .menu-item__heading {
  font-size: 1.2rem;
  font-family: "Cambria", Georgia, serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.menu-item,
.menu-section > div {
  margin-bottom: 1rem;
}

.menu-description {
  padding: 2rem 0 1.5rem;
}

.menu-description h2,
.menu-description p {
  color: #000;
}

.menu-description h2 {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 0;
}

.menu-description {
  margin-bottom: 2.5rem;
}

.menu-section {
  margin-bottom: 2.5rem;
}

.menu-section > div {
  margin-bottom: 2.5rem;
}

.menu-section > ul {
  padding-left: 0;
  list-style: none;
}

.menu-item {
  margin-bottom: 2.5rem;
}

.menu-item__heading {
  font-family: "Open Sans Regular";
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 0;
}

.menu-item__currency {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.menu-item p {
  margin-bottom: 0;
}

.menu-item p.menu-item__details--price {
  margin-bottom: 0em;
}

.menu-item--drink p.menu-item__details--price {
  margin-bottom: 1em;
}

.tab {
  margin-left: 20px;
}

.menu-qr {
  display: none;
}

@media print {
  .menu-qr {
    display: block !important;
    margin-top: 80px;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
